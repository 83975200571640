<template>
  <b-modal
  ref="modal"
  hide-header
  hide-footer
  @ok="confirm"
  size="xl"
  >
    <div class="card">
      <div class="card-body">
        <div class="text-center">
          <i class="bx bxs-info-circle h1 text-info"></i>
          <h4 class="mt-0">Transaction Breakdown</h4>
        </div>

        <div class="row">
          <div class="col-12">
            <Table
            :headers="headers"
            :items="viewTransaction"
            />
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import _ from "lodash";
  import Table from "@/components/tables/BasicTable"

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  export default {
    components: {
      Table
    },

    data() {
      return {
        viewTransaction: {},
        headers: [
          {
            text: "Machine Serial Number",
            value: "gamebox.serialNumber"
          },
          {
            text: "Machine Name",
            value: "gamebox.name"
          },
          {
            text: "Game Name",
            value: "game.name"
          },
          {
            text: "Transaction Date",
            value: "transactionDate"
          },
          {
            text: "Money In",
            value: "totalIn",
            type:"money"
          },
          {
            text: "Money Out",
            value: "totalOut",
            type: "money"
          }
        ],

        items:[{

        }]
      }
    },

    methods: {
      openModal(transaction) {
        this.viewTransaction = _.cloneDeep(transaction);
        this.$refs['modal'].show()
      },

      formatAsMoney(value) {
        return formatter.format(value);
      },

      confirm() {
        this.$nextTick(() => {
          this.$refs['modal'].hide();
        })
      }
    }
  }
</script>

<style scoped>

</style>