<template>
	<div>
		<b-modal
			:no-close-on-backdrop="true"
			:no-close-on-esc="true"
			:hide-header-close="true"
			:ok-only="true"
			ok-variant="success"
			ok-title="Confirm"
			id="reinjection-branch-modal"
			centered
			@ok="confirmFn(details._id)"
			title="Confirm Float Reinjection"
		>
			<p class="font-weight-medium">
				Hello
				{{
					$store.state.authModule.currentUser
						? $store.state.authModule.currentUser.name
						: "manager"
				}},
			</p>
			<div v-if="details">
				<p>
					Please note that a float amount of {{ details.additionalFloat }} was
					sent to your location on {{ details.transactionDate }}.
				</p>
				<p>
					Click on
					<span class="text-success font-weight-medium">Confirm</span> below to
					acknowledge receipt of this amount. If this amount is not delivered to
					you by end of day, feel free to reach out to the admin.
				</p>
			</div>
		</b-modal>
	</div>
</template>

<script>
	export default {
		props: {
			details: Object,
			confirmFn: Function,
		},
	};
</script>

<style></style>
