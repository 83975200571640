<script>
const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
});

import {
    Bar
} from 'vue-chartjs'

export default {
    extends: Bar,

    props: {
      valueOne: Array,
      valueThree: Array,
      valueTwo: Array,
      labelList: Array
    },

    mounted() {
      this.render(this.labelList, this.valueOne, this.valueTwo, this.valueThree)
    },

    methods: {
      render(labelList, arr1, arr2, arr3) {
        this.renderChart({
          labels: labelList,
          datasets: [
            {
              barThickness: 10,
              barPercentage: 0.7,
              categoryPercentage: 0.5,
              label: 'Money In',
              backgroundColor: '#00FFFF',
              borderColor: '#00FFFF',
              hoverBackgroundColor: '#00FFFF',
              hoverBorderColor: '#00FFFF',
              data: arr1,
            },
            {
              barThickness: 10,
              barPercentage: 0.7,
              categoryPercentage: 0.5,
              label: 'Money Out',
              backgroundColor: '#1abc9c',
              borderColor: '#1abc9c',
              hoverBackgroundColor: '#1abc9c',
              hoverBorderColor: '#1abc9c',
              data: arr2,
            },
            {
              barThickness: 10,
              barPercentage: 0.7,
              categoryPercentage: 0.5,
              label: 'Commission',
              backgroundColor: '#e3eaef',
              borderColor: '#e3eaef',
              hoverBackgroundColor: '#e3eaef',
              hoverBorderColor: '#e3eaef',
              data: arr3,
            }],
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: false,
          },
          tooltips: {
            callbacks: {
              label: function(t, d) {
                var xLabel = d.datasets[t.datasetIndex].label;
                var yLabel = formatter.format(t.yLabel);  
                return xLabel + ': ' + yLabel;
              }
            }
          },
          scales: {
            yAxes: [{ 
              gridLines: {
                  display: false,
              },
              stacked: false,
              ticks: {
                  callback: (label, index, labels) => {return formatter.format(label)}
                  // stepSize: 10000,
              },
            }, ],
            xAxes: [{
              stacked: false,
              gridLines: {
                  color: 'rgba(0,0,0,0.01)',
              },
            }, ],
          },
        }
        
        
        )
      }
    }
}
</script>
