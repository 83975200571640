<template>
	<b-modal ref="modal" hide-header hide-footer size="xl" @ok="confirm">
		<div class="card">
			<div v-if="transactions && transactions.length > 0" class="card-body">
				<div class="text-center">
					<i class="bx bxs-info-circle h1 text-info"></i>
					<h4 class="mt-2">Sign For Transactions</h4>
				</div>

				<div class="row">
					<div class="col-12">
						<Table :headers="headers" :items="transactions" />
					</div>
				</div>

				<div class="form-group my-4">
					<div class="custom-control custom-checkbox">
						<input
							type="checkbox"
							class="custom-control-input"
							id="customCheck1"
							v-model="accepted"
						/>
						<label class="custom-control-label" for="customCheck1"
							>I {{ currentUser ? currentUser.name : "" }} accept this
							transaction.</label
						>
					</div>
				</div>

				<div class="text-center">
					<button
						type="button"
						class="btn btn-info mt-3"
						data-dismiss="modal"
						:disabled="!accepted"
						@click="confirm"
					>
						Continue
					</button>
				</div>
			</div>
			<div v-else>
				<div class="text-center">
					<i class="bx bxs-info-circle h1 text-info"></i>
					<h4 class="mt-2">No Transactions to sign for the selected date</h4>
				</div>
			</div>
		</div>
	</b-modal>
</template>

<script>
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
		// These options are needed to round to whole numbers if that's what you want.
		//minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
		//maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
	});

	import { mapActions, mapState } from "vuex";
	import Table from "@/components/tables/BasicTable";
	export default {
		components: {
			Table,
		},

		data() {
			return {
				accepted: false,
				transactions: [],
				headers: [
					{
						text: "Employee Name",
						value: "user.name",
					},
					{
						text: "Transaction Date",
						value: "transactionDate",
					},
					{
						text: "Machine Name",
						value: "gamebox.name",
					},
					{
						text: "Money In",
						value: "totalIn",
						type: "money",
					},
					{
						text: "Money Out",
						value: "totalOut",
						type: "money",
					},
					{
						text: "Commission",
						value: "commission",
						type: "money",
					},
				],
			};
		},

		computed: {
			...mapState("authModule", ["currentUser"]),
		},

		methods: {
			...mapActions("transactionsModule", ["confirmTransaction"]),

			openModal(items) {
				this.transactions = [...items];
				this.$refs["modal"].show();
			},

			async confirm() {
				for (let i = 0; i < this.transactions.length; i++) {
					const result = await this.confirmTransaction({
						transactionId: this.transactions[i]._id,
					});
					if (result.status == 200) {
						this.$bvToast.toast(
							`Transactions for machine: ${result.data.gamebox.name} was confirmed successfully`,
							{
								title: `Transaction Confirmed`,
								variant: "success",
								solid: true,
							}
						);
					} else {
						this.$bvToast.toast(
							`Transactions for machine: ${result.data.gamebox.name} was not confirmed. Please try again`,
							{
								title: `Transaction Not Confirmed`,
								variant: "danger",
								solid: true,
							}
						);
					}
				}

				this.$refs["modal"].hide();
			},

			formatAsMoney(value) {
				return formatter.format(value);
			},
		},
	};
</script>

<style scoped></style>
