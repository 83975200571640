<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<div class="row">
			<ConfirmModal ref="confirmModal" />
			<ViewModal ref="viewModal" />
			<div class="col-xl-4 col-md-8">
				<div class="widget-simple text-center card">
					<div class="card-body">
						<h3 class="text-success counter mt-0">
							{{ widgetData.games.number }}
						</h3>
						<p class="text-muted mb-0">{{ widgetData.games.text }}</p>
					</div>
				</div>
			</div>
			<!-- end col -->
			<div class="col-xl-4 col-md-8">
				<div class="widget-simple text-center card">
					<div class="card-body">
						<h3 class="text-success counter mt-0">
							{{ widgetData.gameboxes.number }}
						</h3>
						<p class="text-muted mb-0">{{ widgetData.gameboxes.text }}</p>
					</div>
				</div>
			</div>
			<!-- end col -->
			<div class="col-xl-4 col-md-8">
				<div class="widget-simple text-center card">
					<div class="card-body">
						<h3 class="text-success counter mt-0">
							{{ widgetData.commission.number }}
						</h3>
						<p class="text-muted mb-0">{{ widgetData.commission.text }}</p>
					</div>
				</div>
			</div>
			<!-- end col -->
		</div>

		<div class="row">
			<div class="col-md-2">
				<div class="form-group">
					<label for="branch">Select A Year</label>
					<date-picker
						value-type="YYYY-MM-DD"
						format="YYYY"
						v-model="filters.monthly"
						type="year"
						lang="en"
						placeholder="Select Year"
					/>
				</div>
			</div>
			<div class="col-md-2">
				<div class="form-group">
					<label for="branch">Select A Month</label>
					<date-picker
						value-type="YYYY-MM-DD"
						format="MMMM YYYY"
						v-model="filters.weekly"
						type="month"
						lang="en"
						placeholder="Select Month"
					/>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-lg-12">
				<div class="card">
					<div class="card-body">
						<BarChart
							:valueOne="moneyInList"
							:valueTwo="moneyOutList"
							:valueThree="commissionList"
							:labelList="labelList"
							ref="barChart"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md-4 col-sm-6">
				<div class="form-group">
					<label for="startDate">Date of reading</label>
					<date-picker
						value-type="YYYY-MM-DD"
						format="MMM DD, YYYY"
						v-model="startDate"
						name="startDate"
						id="startDate"
						type="date"
						lang="en"
						confirm
						placeholder="Select A Date"
					/>
				</div>
			</div>
			<div class="col-lg-12">
				<Table
					title="Transactions"
					subtitle="A list of all the recent transactions that need confirmation by the branch manager"
					@edit="openConfirmModal"
					@view="openViewModal"
					:headers="headers"
					:items="transactions"
				/>
			</div>
		</div>
		<!-- Reinjection modal for branch admin -->
		<ConfirmFloatReinjectionModal
			:confirmFn="confirmFloat"
			:details="floatReinjections[0]"
		/>
	</Layout>
</template>

<script>
	import Layout from "../../../layouts/vertical";
	import PageHeader from "@/components/Page-header";
	import Table from "@/components/tables/BasicTable";
	import { mapActions, mapState, mapGetters } from "vuex";
	import DatePicker from "vue2-datepicker";
	import BarChart from "@/components/charts/CustomBarChart";
	import ConfirmModal from "@/components/modals/ConfirmTransactionModal";
	import ViewModal from "@/components/modals/ViewTransactionModal";
	import ConfirmFloatReinjectionModal from "@/components/modals/ConfirmFloatReinjectionModal.vue";
	import dayjs from "dayjs";
	const formatter = new Intl.NumberFormat("en-US", {
		style: "currency",
		currency: "USD",
	});

	Date.prototype.getWeek = function () {
		var date = new Date(this.getTime());
		date.setHours(0, 0, 0, 0);
		// Thursday in current week decides the year.
		date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
		// January 4 is always in week 1.
		var week1 = new Date(date.getFullYear(), 0, 4);
		// Adjust to Thursday in week 1 and count number of weeks from date to week1.
		return (
			1 +
			Math.round(
				((date.getTime() - week1.getTime()) / 86400000 -
					3 +
					((week1.getDay() + 6) % 7)) /
					7
			)
		);
	};

	/**
	 * Starter component
	 */
	export default {
		components: {
			Layout,
			PageHeader,
			Table,
			DatePicker,
			BarChart,
			ConfirmModal,
			ViewModal,
			ConfirmFloatReinjectionModal,
		},
		page: {
			title: "Branch Manager Dashboard",
		},
		async created() {
			await this.getGames();
			await this.getGameboxes();
			const { data } = await this.getTransactions({
				branchId: this.$store.state.authModule.currentUser?.branch?._id,
			});
			this.floatReinjections = data.results.filter(
				(t) => t.readingType == "FLOAT-REINJECTION" && !!!t.confirmed
			);
			// if (this.floatReinjections.length > 0) {
			// 	this.$bvModal.show("reinjection-branch-modal");
			// }

			//Initalizing Monthly Filter Date Picker value
			const today = new Date().toISOString().substr(0, 10).split("-");
			this.filters.monthly = new Date(today[0], 0, 1)
				.toISOString()
				.substr(0, 10);

			//Set start date
			this.startDate = dayjs().format("YYYY-MM-DD");

			//Initalize Bar Graph
			await this.loadGraphData();
			//Initalizing Widget Data
			this.loadWidgetData();

			//Initializing Transactions Data Table
			this.loadTableData();
		},

		computed: {
			...mapState("gameModule", ["totalGames"]),
			...mapState("gameboxModule", ["totalGameboxes"]),
			...mapGetters("gameboxModule", ["getGameboxByBranch"]),
		},

		data() {
			return {
				title: "Dashboard",
				floatReinjections: [],
				items: [
					{
						text: "Dashboard",
						active: true,
					},
				],
				widgetData: {
					games: {
						text: "Total Games",
						number: 0,
					},
					gameboxes: {
						text: "Total Machines",
						number: 0,
					},
					commission: {
						text: "Total Commission",
						number: "$0",
					},
				},
				headers: [
					{
						text: "Amount Left At Bar",
						value: "amountLeftAtBar",
						type: "money",
					},
					{
						text: "Money In",
						value: "totalIn",
						type: "money",
					},
					{
						text: "Money Out",
						value: "totalOut",
						type: "money",
					},
					{
						text: "Commission",
						value: "commission",
						type: "money",
					},
					{
						text: "Actions",
						value: "actions",
						type: ["edit"],
					},
				],

				transactions: [],

				moneyInList: [],
				moneyOutList: [],
				commissionList: [],
				labelList: [
					"Jan",
					"Feb",
					"Mar",
					"Apr",
					"May",
					"Jun",
					"Jul",
					"Aug",
					"Sep",
					"Oct",
					"Nov",
					"Dec",
				],

				filters: {
					weekly: "",
					monthly: "",
				},
				startDate: null,
				singleTransactions: [],
			};
		},

		methods: {
			...mapActions("gameModule", ["getGames"]),
			...mapActions("gameboxModule", ["getGameboxes"]),
			...mapActions("transactionsModule", [
				"getMonthOrWeekTransaction",
				"getTransactions",
				"getTransactionSummary",
				"confirmTransaction",
				"getTransactionSummaryDetails",
			]),

			/**
			 * Confirms float reinjection from branch admin
			 */
			async confirmFloat(id) {
				const res = await this.confirmTransaction({ transactionId: id });
				this.$router.go(0);
			},

			//gets and loads the data that card widgets will use
			async loadWidgetData() {
				const summary = await this.getTransactionSummary({
					branchId: this.$store.state.authModule.currentUser?.branch?._id,
				});
				this.widgetData.games.number = this.totalGames;
				this.widgetData.gameboxes.number = this.getGameboxByBranch(
					this.$store.state.authModule.currentUser?.branch?._id
				).length;
				if (summary.status == 200) {
					if (Array.isArray(summary.data) && summary.data.length > 0) {
						const { data } = summary;
						this.widgetData.commission.number = formatter.format(
							data[0].commission
						);
					}
				}
			},

			//Finds the start and end date range for an entire month or year
			getFilterDates() {
				let startDate = "";
				let endDate = "";
				let period = "monthly";

				if (this.filters.weekly != "") {
					period = "weekly";
					startDate = dayjs(this.filters.weekly)
						.startOf("month")
						.format("YYYY-MM-DD");
					endDate = dayjs(this.filters.weekly)
						.endOf("month")
						.format("YYYY-MM-DD");
				} else if (this.filters.monthly != "") {
					period = "monthly";
					startDate = dayjs(this.filters.monthly)
						.startOf("year")
						.format("YYYY-MM-DD");
					endDate = dayjs(this.filters.monthly)
						.endOf("year")
						.format("YYYY-MM-DD");
				}
				return { startDate, endDate, period };
			},

			async loadGraphData() {
				const { startDate, endDate, period } = this.getFilterDates();
				const result = await this.getMonthOrWeekTransaction({
					period,
					startDate,
					endDate,
				});

				if (Array.isArray(result.data)) {
					//List of data points for the graph
					this.moneyInList = [];
					this.moneyOutList = [];
					this.commissionList = [];

					if (period == "weekly") {
						const { data } = result;
						for (let i = 0; i < data.length; i++) {
							let summary = data[i];
							let week = summary._id;
							//Weeks of the year is used as the index for each data point
							this.moneyInList[week - 1] = data[i].totalIn;
							this.moneyOutList[week - 1] = data[i].totalOut;
							this.commissionList[week - 1] = data[i].commission;
						}
						//Remove all the leading indexes that don't have data
						//leaving behind the last 4 indexes representing the 4 weeks of the month
						this.moneyInList.splice(0, this.moneyInList.length - 4);
						this.moneyOutList.splice(0, this.moneyOutList.length - 4);
						this.commissionList.splice(0, this.commissionList.length - 4);
					} else if (period == "monthly") {
						const { data } = result;
						for (let i = 0; i < data.length; i++) {
							let summary = data[i];
							let date = (summary._id + "-01").split("-");
							let dateTime = Date.UTC.apply(null, date);
							let month = new Date(dateTime).getUTCMonth();

							//The month of the year is used as the index for the data points
							this.moneyInList[month - 1] = data[i].totalIn;
							this.moneyOutList[month - 1] = data[i].totalOut;
							this.commissionList[month - 1] = data[i].commission;
						}
					}
				}
				if (this.filters.monthly)
					this.labelList = [
						"Jan",
						"Feb",
						"Mar",
						"Apr",
						"May",
						"Jun",
						"Jul",
						"Aug",
						"Sep",
						"Oct",
						"Nov",
						"Dec",
					];
				else this.labelList = ["Week #1", "Week #2", "Week #3", "Week #4"];

				this.$refs["barChart"].render(
					this.labelList,
					this.moneyInList,
					this.moneyOutList,
					this.commissionList
				);
			},

			async openConfirmModal(item) {
				const result = await this.getTransactions({
					branchId: this.$store.state.authModule.currentUser?.branch?._id,
					startDate: this.startDate,
					readinType: "REGULAR",
				});
				if (result.status == 200) {
					const { data } = result;
					if (Array.isArray(data.results)) {
						let tr = data.results.filter(
							(r) =>
								r.readingType != "FLOAT-REINJECTION" && r.confirmed == false
						);
						this.$refs.confirmModal.openModal(tr);
					}
				}
			},

			async openViewModal(item) {
				const result = await this.getTransactions({
					branchId: this.$store.state.authModule.currentUser?.branch?._id,
					startDate: item.startOfPeriod,
					endDate: item.endOfPeriod,
				});
				if (result.status == 200) {
					const { data } = result;
					if (Array.isArray(data.results)) {
						this.$refs.viewModal.openModal(data.results);
					}
				}
			},

			async loadTableData() {
				try {
					const result = await this.getTransactionSummaryDetails({
						branchId: this.$store.state.authModule.currentUser?.branch?._id,
						startDate: this.startDate,
					});
					this.transactions = result.data.filter(
						(t) => t._id.readingDate == this.startDate
					);
					this.loadSingleTransactions();
				} catch (error) {
					console.log(error);
				}
			},
			/**
			 * Load all the transactions for the selected date
			 */
			async loadSingleTransactions() {
				try {
					const result = await this.getTransactions({
						branchId: this.$store.state.authModule.currentUser?.branch?._id,
						startDate: this.startDate,
						readinType: "REGULAR",
					});

					const { data } = result;
					if (Array.isArray(data.results)) {
						const { results } = data;
						this.singleTransactions = results.filter(
							(r) => r.readingType != "FLOAT-REINJECTION"
						);
					}
				} catch (error) {
					console.log(error);
				}
			},
		},

		watch: {
			"filters.weekly": function (newVal, oldVal) {
				if (newVal != oldVal) {
					if (newVal != "") {
						this.filters.monthly = "";
						this.loadGraphData();
					}
				}
			},

			"filters.monthly": function (newVal, oldVal) {
				if (newVal != oldVal) {
					if (newVal != "") {
						this.filters.weekly = "";
						this.loadGraphData();
					}
				}
			},

			startDate: function (newVal, oldVal) {
				if (newVal != oldVal) {
					this.loadTableData();
				}
			},
		},
	};
</script>
